<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-text-field
          v-model="code"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          label="Nome"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-text-field
          v-model="description"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          label="Descrizione"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-autocomplete
          v-model="categoryIds"
          :items="categories"
          filled
          multiple
          dense
          label="Categorie"
          item-text="code"
          item-value="id"
          chips
          small-chips
          deletable-chips
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import enablingTypeForm from '@/services/enablingTypes/enablingType.form.js'
import enablingTypeCategoriesSerivice from '@/services/enablingTypeCategories/enablingTypeCategories.service.js'

export default {
  name: "GeneralFormTab",
  components: {},
  data: function() {
    return {
      code: undefined,
      description: undefined,
      loadingEnablingType: false,
      formValid: false,
      categories: [],
      categoryIds: [],
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    }
  },
  mounted: function() {
    enablingTypeCategoriesSerivice.list().then((categoriesResults) => {
      this.categories = categoriesResults.rows
      this.handleObjectChanges(enablingTypeForm.enablingType)

      var self = this
      enablingTypeForm.on('update', function(data) {
        self.handleObjectChanges(data.enablingType)
      })
    })
  },
  methods: {
    fields() {
      return ['code', 'description']
    },
    handleObjectChanges(enablingType) {
      const fields = this.fields()
      const newValKeys = Object.keys(enablingType)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && enablingType[field] != this[field]) {
          this[field] = enablingType[field]
        }
      }

      if(enablingType.enablingTypeCategories) {
        const categoryIds = enablingType.enablingTypeCategories.map((el) => { return el.id})
        enablingTypeForm.updateField('enablingTypeCategories', null)
        enablingTypeForm.updateField('categoryIds', categoryIds)
      }

      if(enablingType.categoryIds) {
        if(!_.isEqual(this.categoryIds, enablingType.categoryIds)) {
          this.categoryIds = enablingType.categoryIds
        }
      }
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      enablingTypeForm.setValid(newVal)
    },
    code(newVal) {
      enablingTypeForm.updateField('code', newVal)
    },
    description(newVal) {
      enablingTypeForm.updateField('description', newVal)
    },
    categoryIds(newVal) {
      enablingTypeForm.updateField('categoryIds', newVal)
    }
  }
};
</script>